/**
 * Generic Modals
 * @provides $.modalize
 * @requires base.js
 **/
(function($){
    /**
     * Turn a link and a container into a modal
     **/
    $.fn.modalize = function(params){
        var defaults = {
            modalSelector:".modal",
            closeSelector:".modal-close",
            modalLinkActiveClass:"h-modal-link--active",
            modalActiveClass:"modal--active",
            reparent:"body",
            modalLinkPreventDefault:true,
            modalClosePreventDefault:true,
            openOnMouseOver:false,
            closeAllBeforeOpen:false,
            closeOnEscape:true,
            closeSilently : false,
            closeOnDocumentClick:true,
            closeOnDocumentTap:false,
            repositionOnResize:false,
            repositionOnResizeTimeout:150,
            position:{
                my:"center",
                at:"center",
                of:$(window)
            },
            opener:null,
            closer:null,
            eventPrefix:"h.modal"
        };

        if(typeof $.isTouchCapable === "function" && $.isTouchCapable()){
            defaults.closeOnDocumentTap = true;
        }

        var options = $.extend(defaults, params);

        function reposition(e, link, modal){
            link.trigger(options.eventPrefix + ".repositioning",  [e, link, modal]);
            modal.trigger(options.eventPrefix + ".repositioning", [e, link, modal]);

            var pos = options.position;
            if(typeof pos === "function"){
                pos = pos(e, link, modal);
            }
            modal.position(pos);

            link.trigger(options.eventPrefix + ".repositioned",  [e, link, modal]);
            modal.trigger(options.eventPrefix + ".repositioned", [e, link, modal]);
        }

        function open(e, link, modal){
            if ($(".modal--full.modal--active").length){
                $(".modal--full.modal--active").addClass("modal-transition-none");
                modal.addClass("modal-transition-none");
                if(options.opener){
                    options.opener(options, e, link, modal);
                } else {
                    link.addClass(options.modalLinkActiveClass);
                    modal.addClass(options.modalActiveClass);
                }
                link.trigger(options.eventPrefix + ".opened", [e, link, modal]);
                modal.trigger(options.eventPrefix + ".opened", [e, link, modal]);
                $(document).trigger(options.eventPrefix + ".close-all-silent", [modal]);
            }
            else {
                if(options.closeAllBeforeOpen){
                    $(document).trigger(options.eventPrefix + ".close-all");
                }
                link.trigger(options.eventPrefix + ".opening", [e, link, modal]);
                modal.trigger(options.eventPrefix + ".opening", [e, link, modal]);

                if(options.opener){
                    options.opener(options, e, link, modal);
                } else {
                    link.addClass(options.modalLinkActiveClass);
                    modal.addClass(options.modalActiveClass);
                }

                if(options.position){
                    reposition(e, link, modal);
                }

                link.trigger(options.eventPrefix + ".opened", [e, link, modal]);
                modal.trigger(options.eventPrefix + ".opened", [e, link, modal]);
                var data = modal.data()||{};
                if(!modal.hasClass("modal--half") && !modal.hasClass("header-nav-modal") && !modal.hasClass("modal--item-added") && data.modalType !== 'third' && data.modalType !== 'quarter') {
                    options.scrollTop = window.pageYOffset;
                    $("html,body").addClass("no-scroll");
                }
                if(modal.hasClass("modal--item-added")) {
                    options.scrollTop = window.pageYOffset;
                    $("html,body").addClass("mobile-no-scroll");
                }
            }

            if (options.closeSilently){
                $(document).trigger(options.eventPrefix + ".close-all-silent", [modal]);
            }
        }

        function close(e, link, modal){
            modal.removeClass("modal-transition-none")
            link.trigger(options.eventPrefix + ".closing",  [e, link, modal]);
            modal.trigger(options.eventPrefix + ".closing", [e, link, modal]);

            if(options.closer){
                options.closer(options, e, link, modal);
            } else {
                link.removeClass(options.modalLinkActiveClass);
                modal.removeClass(options.modalActiveClass);
            }

            link.trigger(options.eventPrefix + ".closed",  [e, link, modal]);
            modal.trigger(options.eventPrefix + ".closed", [e, link, modal]);

            if (!close._noScrollTimeoutChecker) {
                close._noScrollTimeoutChecker = setTimeout(function() {
                    if ($(".modal.modal--active").length == 0) {
                        $("html,body").removeClass("no-scroll");
                        $("html,body").removeClass("mobile-no-scroll");
                    }
                    close._noScrollTimeoutChecker = null;
                });
            }
            if (options.scrollTop) {
                window.scrollTo(0, options.scrollTop);
                options.scrollTop = 0;
            }
        }

        function closeSilent(e, link, modal, me){
            if (me.selector !== modal.selector) {
                if (options.closer) {
                    options.closer(options, e, link, modal, me);
                } else {
                    link.removeClass(options.modalLinkActiveClass);
                    modal.removeClass(options.modalActiveClass);
                 //   modal.removeClass("modal-transition-none");

                }
            }
        }

        return $(this).each(function(){
            var link = $(this);
            var selector = options.modalSelector;
            if(typeof selector === "function"){
                selector = selector(link);
            }
            var modal = $(selector, link);

            // reparent if needed
            if(options.reparent){
                if(typeof options.reparent === "function"){
                    options.reparent(modal, link);
                } else {
                    $(options.reparent).append(modal);
                }
            }

            // if close on escape
            if(options.closeOnEscape){
                $(document).on("h.escape", function(e){
                    modal.trigger(options.eventPrefix + ".close", e);
                });
            }

            // if close selector
            if(options.closeSelector){
                $(options.closeSelector, modal).on("click", function(e){
                    if(e && options.modalClosePreventDefault){
                        e.preventDefault();
                    }

                    modal.trigger(options.eventPrefix + ".close", e);
                });
            }

            // if close on click
            if(options.closeOnDocumentClick){
                // listen for clicks anywhere in the document.
                $(document).on("click", function(e){
                    if (modal.get(0) && !$.contains(modal.get(0), e.target) && !$(e.target).hasClass('loadingoverlay')) {
                        modal.trigger(options.eventPrefix + ".close", e);
                    }
                });


            }

            // if close on tap
            if(options.closeOnDocumentTap){
                // listen for clicks anywhere in the document.
                $(document).on("singletap", function(e){
                    modal.trigger(options.eventPrefix + ".close", e);
                });

                // but, don't allow clicks in the modal to propagate outside the modal
                // as that would inadvertently close the modal
                modal.on("singletap", function(e){
                    if(e){
                        e.stopPropagation();
                    }
                });
            }

            // if reposition on resize
            if(options.repositionOnResize){
                var timeout = null;

                var triggerReposition = function(){
                    modal.trigger(options.eventPrefix + ".reposition");
                };

                var handleResize = function(){
                    if(timeout){
                        clearTimeout(timeout);
                        timeout = null;
                    }
                    setTimeout(triggerReposition, options.repositionOnResizeTimeout);
                };

                modal.on(options.eventPrefix + ".opened", function(){
                    $(window).on("resize", handleResize);
                });

                modal.on(options.eventPrefix + ".closed", function(){
                    $(window).off("resize", handleResize);
                });
            }

            // if hover instead of click
            if(options.openOnMouseOver){

                var hoverTimeout = null;
                var exitTimeout = null;

                link.on({
                    mouseenter:function(e){
                        if(hoverTimeout){
                            clearTimeout(hoverTimeout);
                            hoverTimeout = null;
                        }
                        $(document).trigger(options.eventPrefix + ".close-all");
                        hoverTimeout = setTimeout(function(){
                            modal.trigger(options.eventPrefix + ".open");
                            hoverTimeout = null;
                        }, 150);
                    },
                    mouseleave:function(e){
                        if(hoverTimeout){
                            clearTimeout(hoverTimeout);
                            hoverTimeout = null;
                        }
                        exitTimeout = setTimeout(function(){
                            modal.trigger(options.eventPrefix + ".close");
                            exitTimeout = null;
                        }, 250);
                    }
                });

                modal.on({
                    mouseenter:function(e){
                        if(exitTimeout){
                            clearTimeout(exitTimeout);
                            exitTimeout = null;
                        }
                    },
                    mouseleave:function(e){
                        if(exitTimeout){
                            clearTimeout(exitTimeout);
                            exitTimeout = null;
                        }
                        exitTimeout = setTimeout(function(){
                            modal.trigger(options.eventPrefix + ".close");
                            exitTimeout = null;
                        }, 150);
                    }
                });

            }

            if(!options.openOnMouseOver || options.openOnMouseClick){
                // bind the event handler for the links
                link.on("click", function(e){
                    if(e && options.modalLinkPreventDefault){
                        e.preventDefault();
                        e.stopPropagation();
                    }

                    if(modal.hasClass(options.modalActiveClass)){
                        modal.trigger(options.eventPrefix + ".close", e);
                    } else {
                        modal.trigger(options.eventPrefix + ".open", e);
                    }
                });
            }

            // listen for a close all event
            $(document).on(options.eventPrefix + ".close-all", function(e){
                modal.trigger(options.eventPrefix + ".close", e);
            });

            // listen for a close all event
            $(document).on(options.eventPrefix + ".close-all-silent", function(e, m){
                modal.trigger(options.eventPrefix + ".close-silent", [link, modal, m]);
            });

            modal.on(options.eventPrefix + ".open", function(e){
                open(e, link, modal);
            });

            modal.on(options.eventPrefix + ".close", function(e){
                close(e, link, modal);
            });

            modal.on(options.eventPrefix + ".close-silent", function(e, l, m, me){
                closeSilent(e, link, modal, me);
            });

            modal.on(options.eventPrefix + ".reposition", function(e){
                reposition(e, link, modal);
            });

            var exports = {
                "m":modal,
                "l":link
            };

            link.data("modal", exports);
            modal.data("modal", exports);
        });
    };

    $(function(){
        $('.product-modal-links a[data-modal], .header a[data-modal], .modal--item-added a[data-modal], .size-modal-column[data-modal], .info-modal-column a[data-modal], .ecosystem-column a[data-modal]').not('.open-header-nav-modal').modalize({
            
            modalActiveClass:'modal--active',
            modalLinkActiveClass:'  header__menu-link--active',
            reparent:false,
            position:false,
            closeSilently: true,
            closeAllBeforeOpen:true,
            modalSelector:function(link){
                var modal = link.data('modal');
                if(!modal){
                    return null;
                }

                return $('div[data-modal="' + modal + '"]');
            }
        }).on('h.modal.opened', function(origE, e, link, modal){          
            if(link.attr("data-modal")=="account") {
                $('.header').addClass('header--active');
                //nothing to do, open modal
            } else {
                if(($(modal).data()||{}).modalType !== 'third'){
                    $('.header').addClass('header--active');
                }
            }
            //todo what could this possibly do

            $(modal).find('input[name=query]').trigger("focus");
        }).on('h.modal.closed', function(origE, e, link, modal){
            setTimeout(function() {$(modal).removeClass('modal--opaque');});
            if(($(modal).data()||{}).modalType !== 'quarter'){
                $('.header').removeClass('header--active');
            }
        });

        $('.close-icon-container .w2-close').on('click', function() {
            $('.new-search-bar').trigger('h.modal.close');
        });

        // PDP modals
        $('.js-product-modal-links a[data-modal]').modalize({
            position:false,
            reparent:function(modal){
                modal.insertAfter(".footer");
            },
            closeAllBeforeOpen:true,
            modalSelector:function(link){
                var modal = link.data('modal');
                if(!modal){
                    return null;
                }
                return $('div[data-modal="' + modal + '"]');
            }
        });

        $('a[data-modal="accessories"]').modalize({
            position:false,
            reparent:function(modal){
                modal.insertAfter(".footer");
            },
            closeAllBeforeOpen:true,
            modalSelector:function(link){
                var modal = link.data('modal');
                if(!modal){
                    return null;
                }
                return $('div[data-modal="' + modal + '"]');
            }
        });


        $('h3[data-modal="rating-reviews"]').modalize({
            position:false,
            reparent:function(modal){
                //modal.insertAfter(".footer");
            },
            closeAllBeforeOpen:true,
            modalSelector:function(link){
                var modal = link.data('modal');
                if(!modal){
                    return null;
                }
                return $('div[data-modal="' + modal + '"]');
            }
        });

        $('.region-selector a[data-modal="region"]').modalize({
            position:false,
            closeSilently: true,
            closeAllBeforeOpen:true,
            reparent:function(modal){
                modal.insertAfter(".footer");
            },
            closeAllBeforeOpen:true,
            modalSelector:function(link){
                var modal = link.data('modal');
                if(!modal){
                    return null;
                }
                return $('div[data-modal="' + modal + '"]');
            }
        }).on('h.modal.opened', function(origE, e, link, modal){
            $(modal).show();
        }).on('h.modal.closed', function(origE, e, link, modal){
            $(modal).hide();
        });

        $('.header a.open-header-nav-modal').modalize({
            position:false,
            reparent:function(modal){
                modal.insertAfter(".footer");
            },
            closeAllBeforeOpen:true,
            closeOnDocumentClick: false,
            modalSelector:function(link){
                var modal = link.data('modal');
                if(!modal){
                    return null;
                }
                return $('div[data-modal="' + modal + '"]');
            }
        }).on('h.modal.opened', function(origE, e, link, modal){
            $(modal).show();
        }).on('h.modal.closed', function(origE, e, link, modal){
            $(modal).hide();
        });

        $('a[data-modal="regioncheck"]').modalize({
            position:false,
            reparent:function(modal){
                modal.insertAfter(".footer");
            },
            closeAllBeforeOpen:true,
            closeOnDocumentClick:false,
            modalSelector:function(link){
                var modal = link.data('modal');
                if(!modal){
                    return null;
                }
                return $('div[data-modal="' + modal + '"]');
            }
        }).on('h.modal.opened', function(origE, e, link, modal){
            $(modal).show();
        }).on('h.modal.closed', function(origE, e, link, modal){
            $(modal).hide();
        });


        $('a[data-modal="itemAdded"]').modalize({
            position:false,
            reparent:function(modal){
                modal.insertAfter(".footer");
            },
            closeAllBeforeOpen:false,
            closeOnDocumentClick:true,
            modalSelector:function(link){
                var modal = link.data('modal');
                if(!modal){
                    return null;
                }
                return $('div[data-modal="' + modal + '"]');
            }
        }).on('h.modal.opened', function(origE, e, link, modal){
            $(modal).show();
        }).on('h.modal.closed', function(origE, e, link, modal){
            $(".modal-overlay.itemAdded").remove();
            $(modal).hide();
        });

        $(".hsco-account-forms a[data-modal]").on("click", function(e){
            e.preventDefault();

            $(".js-signin,.js-register,.js-password").hide();
            var show = $(e.target).data("modal")
            $(".js-"+show).show();

        })
    });

}(window.jQuery));
