const DEFAULT_QUANTITY = 99999

window.Commerce = (function () {
    var USER;
    var SHOPIFY_OPTIONS = Object.keys(window.COMMERCE_OPTIONS)
    .filter((key) => ['shopify'].indexOf(key) < 0)
    .reduce((newObj, key) => Object.assign(newObj, { [key]: window.COMMERCE_OPTIONS[key] }), {})

    var options = $.extend({
        "country" : "/content/herschel/en_US"
    }, SHOPIFY_OPTIONS);

    return {
        checkoutId: localStorage.getItem('checkout-id'), // todo: confirm storage method
        cartData: null,
        customerAccessToken: localStorage.getItem('customer-token'), // todo: confirm storage method
        shopifyProducts: [],
        checkoutUrl: '/',
        hasItemsInCart: false,
        isHeadlessCheckout: false,
        isHeadlessAccount: true,
        prioritizeProductId: true,
        accountActivationEnabled: true,
        productOptionsConcurrencyLimit: 25,
        mapOrderStatus(fulfillmentStatus, financialStatus) {
            // https://help.shopify.com/en/manual/orders/order-status#fulfillment-status
            // https://shopify.dev/docs/api/storefront/2024-04/enums/OrderFulfillmentStatus
            switch(fulfillmentStatus) {
                case 'FULFILLED':
                    return 'SHIPPED'
                case 'PARTIALLY_FULFILLED': // return 'PARTIALLY_SHIPPED'
                case 'UNFULFILLED':
                    if (financialStatus === 'REFUNDED') {
                        return 'CANCELLED'    
                    }
                case 'PENDING_FULFILLMENT':
                case 'IN_PROGRESS':
                    return 'IN_PROGRESS'
                case 'ON_HOLD':
                    return 'ONHOLD'
                case 'OPEN':
                    return 'CREATED'
                case 'RESTOCKED':
                    return 'CANCELLED'
                case 'SCHEDULED':
                    return 'RELEASED'
            }
            
            return 'CREATED'
        },
        transformSelectedOptions (item) {
            const obj = {}
            if (item.variant?.selectedOptions?.length) {
                item.variant.selectedOptions.forEach(option => {
                    if (option.name === 'Size') {
                        obj.size = option.value
                        obj.oneSize = option.value === 'One Size' || option.value === 'OS' // TODO confirm "OS" indeed is equal to "One Size"
                    } else if (option.name === 'Color') {
                        obj.color_name = option.value
                    } else {
                        obj.color_name = ''
                        obj.size = 'N/A'
                    }
                })
            }
            return obj
        },
        transformGiftCardOptions (item) {
            const obj = {}
            if (item?.customAttributes?.length) {
                item.customAttributes.forEach((customAttribute, customAttributeIndex) => {
                    if (customAttribute.key === 'giftCertificate.recipientEmail') {
                        obj.to = customAttribute.value
                    }
                })
            }
            return obj
        },
        transformLineItemCheckout (item, checkout) {
            const me = this
            const discount = (item.discountAllocations.reduce((acc, current) => acc + Number(current?.allocatedAmount?.amount) ?? 0, 0)) / item.quantity
            return {
                id: item.id,
                variant_sku: item.variant.sku,
                display_name: item.title,
                listPrice: +item.variant.compareAtPrice.amount,
                listDisplay: HSCO.Currency.setCurrency(item.variant.compareAtPrice.amount),
                purchasePrice: +item.variant.price.amount,
                purchaseDisplay: HSCO.Currency.setCurrency(item.variant.price.amount),
                discountPrice: +item.variant.price.amount - discount,
                discountDisplay: HSCO.Currency.setCurrency(item.variant.price.amount - discount),
                discountDesc: item.discountAllocations.reduce((acc, current) => acc + "<span>" + current?.discountApplication?.title + "</span>", ""),
                quantity: item.quantity,
                sku: item.variant.sku,
                image_path: me.getImagePath(item.variant.sku),
                cartUrl: item.webUrl,
                subtotal: checkout.subtotalPrice.amount,
                quantityAvailable: item.variant.quantityAvailable,
                ...me.transformSelectedOptions(item),
                ...me.transformGiftCardOptions(item),
                shopifyProductId: item.variant.product.id.replace("gid://shopify/Product/", ""),
                shopifyVariantId: item.variant.id.replace("gid://shopify/ProductVariant/", "")
            }
        },    
        transformLineItemOrder (item, order) {
            const me = this
            const lineItemDiscount = item.discountAllocations.reduce((acc, current) => acc + Number(current?.allocatedAmount?.amount) ?? 0, 0)
            const extendedPrice = Number(item.discountedTotalPrice.amount) - Number(lineItemDiscount)
            const unitPrice = Math.trunc(extendedPrice / item.quantity * 100) / 100
            const percentageOfTotal = unitPrice / order.subtotalPrice.amount
            const unitTax = order.totalTax.amount * percentageOfTotal
            const extendedTax = unitTax * item.quantity
            const unitShipping = order.totalShippingPrice.amount * percentageOfTotal
            const extendedShipping = unitShipping * item.quantity
            const unitDiscount = order.totalDiscount.amount * percentageOfTotal
            const extendedDiscount = unitDiscount * item.quantity

            return {
                id: item.variant?.id,
                productId: item.variant?.product?.id,
                sku: item.variant?.sku,
                quantity: item.quantity,
                name: item.title,
                imagePath: me.getImagePath(item.variant?.sku),
                currency: HSCO.Currency.getCurrency(),
                customAttributes: item.customAttributes?.filter(item => item.key === '_COMPAREATPRICE'),
                extendedTotals: {
                    price: extendedPrice,
                    priceDisplay: HSCO.Currency.setCurrency(extendedPrice),
                    tax: extendedTax,
                    taxDisplay: HSCO.Currency.setCurrency(extendedTax),
                    shipping: extendedShipping,
                    shippingDisplay: HSCO.Currency.setCurrency(extendedShipping),
                    discount: extendedDiscount,
                    discountDisplay: HSCO.Currency.setCurrency(extendedDiscount),
                },
                unitTotals: {
                    price: unitPrice,
                    priceDisplay: HSCO.Currency.setCurrency(unitPrice),
                    tax: unitTax,
                    taxDisplay: HSCO.Currency.setCurrency(unitTax),
                    shipping: unitShipping,
                    shippingDisplay: HSCO.Currency.setCurrency(unitShipping),
                    discount: unitDiscount,
                    discountDisplay: HSCO.Currency.setCurrency(unitDiscount),
                },
                ...me.transformSelectedOptions(item),
                ...me.transformGiftCardOptions(item),
            }
        },    
        getImagePath (sku) {
            if (sku) {
                if (sku.indexOf('SHGC') === 0) {
                    // GIFTCARDS
                    if (typeof HSCO.giftCardProductImage != 'undefined' && HSCO.giftCardProductImage) {
                        return HSCO.giftCardProductImage
                    }

                    return '/content/dam/herschel/products/giftcard/2017-S2-giftcard.jpg'
                }
                else if (sku.indexOf('-') > 0) {
                    return `/bin/herschel/product/image.${sku}.jpg`
                }
            }

            return '/content/dam/herschel/products/no-image.jpg'
        },
        getProductAjax (sku, params) {
            const { variantId, productId } = params
            const deferred = $.Deferred()

            this.getPDPPrices([sku], productId)
            .then(variants => {
                variants.forEach(variant => {
                    if (variant.shopifyId === Number(variantId)) {
                        return deferred.resolve({
                            ecom_colour_description: variant.color_name,
                            ecom_name: variant.title,
                            ecomm_size: variant.size,
                            giftWithPurchase: '',
                            path: '',
                            availableQuantity: variant.availableQuantity
                        })
                    }
                })

                return deferred.resolve({})
            })

            return deferred.promise()
        },
        /**
         * Use Shopify checkout response to prepare cart information
         * Support current format introduced by EP
         *
         * @param {Object} checkout - checkout data from Shopify query
         * @returns {Object} - formatted cart data
         */
        formatCartData (checkout) {
            const me = this
            let quantity = 0
            let discount = 0

            checkout.lineItems?.forEach(lineItem => {
                quantity += lineItem.quantity,
                discount += lineItem.discountAllocations.reduce((acc, current) => acc + Number(current?.allocatedAmount?.amount) ?? 0, 0)
            })

            let data = {
                quantity: quantity,
                discount: discount,
                products: []
            }

            if (checkout.totalPrice) {
                data.totalPrice = checkout.totalPrice.amount
            }

            checkout.lineItems?.forEach(item => {
                const obj = me.transformLineItemCheckout(item, checkout)

                data.products.push(obj)
                data.subtotal = HSCO.Currency.setCurrency(checkout.subtotalPrice.amount)
            })

            return data
        },
        setCheckoutId (checkoutId) {
            localStorage.setItem('checkout-id', checkoutId)
            this.checkoutId = checkoutId
        },
        /**
         * @see {@link https://shopify.dev/api/examples/checkout|Checkout}
         *
         * @returns Promise<Object>} | fulfilled: checkout with removed line item
         */
        getCart () {
            const deferred = $.Deferred()

            Promise.resolve()
                .then(() => {
                    if (this.checkoutId) {
                        return ShopifyService.fetchCheckoutById(this.checkoutId)
                            .then(rsp => {
                                return rsp.data.node
                            })
                    }

                    return Promise.resolve()
                })
                .then(checkout => {
                    if (checkout) {
                        if (checkout && !checkout.completedAt) {
                            return Promise.resolve(checkout)
                        }
                    }

                    return ShopifyService.createCheckout()
                        .then(rsp => {
                            return rsp?.data?.checkoutCreate?.checkout
                        })
                        .catch(e => {
                            console.error(e)
                            deferred.reject({
                                success: false,
                                error: e
                            })
                        })
                })
                .then(checkout => {
                    if (!checkout) {
                        throw "Unable to validate checkout"
                    }

                    this.setCheckoutId(checkout.id)
                    this.hasItemsInCart = checkout.lineItems?.length > 0
                    this.checkoutUrl = checkout.webUrl
                    // update link element with checkout web url to support open in new tab / window feature
                    $('.hsco-checkout-button').attr('href', this.checkoutUrl)
                    const formattedCartData = this.formatCartData(checkout)

                    this.cartData = formattedCartData
                    deferred.resolve(formattedCartData)
                })
                .catch(e => {
                    console.error(e)
                    deferred.reject({
                        success: false,
                        error: e
                    })
                })

            return deferred.promise()
        },
        /**
         * Remove line item from Cart
         *
         * @param {string} url - Product url (used by EP)
         * @param {string} id - A globally-unique identifier
         * @returns {Promise<Object>} | fulfilled: checkout with removed line item
         */
        removeFromCart (url, id) {
            const deferred = $.Deferred()

            ShopifyService.removeLineItem(this.checkoutId, id)
                .then((data) => {
                    deferred.resolve({
                        success: true,
                        data
                    })
                })
                .catch((error) => {
                    deferred.reject({
                        success: false,
                        error
                    })
                })

            return deferred.promise()
        },
        /**
         * Update line item quantity
         *
         * @param {string} url - Product url (used by EP)
         * @param {string} id - A globally-unique identifier
         * @param {number} quantity - new quantity for line item
         * @returns {Promise<Object>} | fulfilled: checkout with updated line items
         */
        updateCartQuantity (url, id, quantity) {
            const deferred = $.Deferred()
            const requestedQuantity = parseInt(quantity)

            if (isNaN(quantity)) {
                return deferred.reject({
                    success: false,
                    responseText: 'Quantity is either missing or is not an integer',
                })
            }

            const foundProduct = this.cartData.products.find(item => item.id === id)

            if (foundProduct?.quantityAvailable < requestedQuantity) {
                return deferred.reject({
                    success: false,
                    responseText: 'Item is not available',
                });
            }

            const lineItemsToUpdate = [{
                id,
                quantity: requestedQuantity
            }]

            ShopifyService.updateLineItems(this.checkoutId, lineItemsToUpdate)
                .then((data) => {
                    if (data?.checkoutLineItemsUpdate?.checkoutUserErrors?.length) {
                        throw new Error(data.checkoutLineItemsUpdate.checkoutUserErrors.map(error => error.message).join('; '))
                    }

                    return deferred.resolve({
                        success: true,
                    })
                })
                .catch(() => {
                    return deferred.reject({
                        success: false,
                        responseText: 'Item is not available',
                    });
                })

            return deferred.promise()
        },
        /**
         *
         * @param {Array.<string>} skus - list of product SKU
         * @param {string|Array<string>} productId - product ID
         * @param {string|Array.<string>} variantFilter - variant ID
         * @returns {Promise.<*>}
         */
        getPDPPrices (skus, productId, variantFilter) {
            const deferred = $.Deferred()
            const me = this
            const normalizeVariantId = (variantId) => {
                const parts = variantId.split('/')

                return Number(parts[parts.length -1])
            }
            // Localizing the String conversion here of productId to keep the damage minimum
            if(productId){
                productId = productId.toString();
            }
            const ids = (productId == null ? "" : productId).split(',').filter(i => !!i)
            
            ShopifyService.getProductsById(ids)
                .then(rsp => {
                    let data = []
                    const products = rsp?.data?.products || []

                    products.forEach(product => {
                        const variants = product?.variants || []
                        const maybeApplyVariantFiltering = (variant) => {
                            if (typeof variantFilter === 'undefined') {
                                return true
                            } else if (typeof variantFilter === 'string') {
                                return variant.id === `gid://shopify/ProductVariant/${variantFilter}`
                            }

                            return variantFilter.find(vid => variant.id === `gid://shopify/ProductVariant/${vid}`)
                        }

                        variants?.filter(maybeApplyVariantFiltering)?.forEach(variant => {
                            const variantId = variant.id
                            const sku = variant.sku
                            const title = product.title
                            const price_amount = +variant.price.amount
                            const price_currency = variant.price.currencyCode
                            const price_display = price_amount + price_currency
                            let salePercentDisplay = 0
                            let purchase_price_amount = price_amount
                            let purchase_price_currency = price_currency
                            let purchase_price_display = price_display
                            let quantityAvailable = variant.quantityAvailable
                            let availableForSale = variant.availableForSale

                            if (variant.quantityAvailable <= 0 && variant.availableForSale) {
                                quantityAvailable = DEFAULT_QUANTITY
                            }

                            if (variant.compareAtPrice != null) {
                                purchase_price_amount = +variant.compareAtPrice.amount
                                purchase_price_currency = variant.compareAtPrice.currencyCode
                                purchase_price_display = purchase_price_amount + purchase_price_currency
                            }

                            if (price_amount !== purchase_price_amount) {
                                salePercentDisplay = Math.round(100 * (purchase_price_amount - price_amount) / purchase_price_amount) + Granite.I18n.get('% OFF')
                            }

                            // Safeguard - we don't want to sell $0 priced products!
                            if (price_amount <= 0) {
                                availableForSale = false;
                                quantityAvailable = 0;
                            }

                            try {
                                data.push({
                                    variantId,
                                    shopifyId: normalizeVariantId(variantId),
                                    sku,
                                    parentId: product.id,
                                    title,
                                    url: product.onlineStoreUrl,
                                    inStock: availableForSale,
                                    listPrice: purchase_price_amount,
                                    listDisplay: HSCO.Currency.setCurrency(purchase_price_display),
                                    purchasePrice: price_amount,
                                    purchaseDisplay: HSCO.Currency.setCurrency(price_display),
                                    availableQuantity: quantityAvailable,
                                    availableForSale,
                                    salePercentDisplay,
                                    ...me.transformSelectedOptions({ variant }),
                                    shopifyProductId: variant.product.id.replace("gid://shopify/Product/", ""),
                                    shopifyVariantId: variant.id.replace("gid://shopify/ProductVariant/", "")
                                })
                            }
                            catch (e) {
                                data.push({
                                    variantId: false,
                                    shopifyId: false,
                                    sku,
                                    parentId: product.id,
                                    url: product.onlineStoreUrl,
                                    inStock: false,
                                    listPrice: 0,
                                    listDisplay: HSCO.Currency.setCurrency(0),
                                    purchasePrice: 0,
                                    purchaseDisplay: HSCO.Currency.setCurrency(0),
                                    salePercentDisplay,
                                    availableForSale: quantityAvailable,
                                })

                                console.info(`Sku ${sku} could not be loaded: ${e.message}`)
                            }
                        })
                    })

                    deferred.resolve(data)
                })

            return deferred.promise()
        },
        /**
         * Add line item to cart
         *
         * @param {Object} product - line item
         * @param {number} quantity
         * @returns {Promise<Object>}
         */
        addToCart (product, quantity) {
            const deferred = $.Deferred();

            if (!this.checkoutId) {
                return deferred.reject({
                    success: false,
                    responseText: 'Unknown error occurred, please reload page and try again',
                })                
            }

            quantity = Math.abs(quantity || 1)

            if (typeof quantity == 'NaN') {
                quantity = 1
            }

            let lineItem = {
                variantId: product.variantId,
                quantity
            }

            lineItem.customAttributes = lineItem.customAttributes || []

            if (window.loopConfig.finalSaleEnabled && product.isOnSale) {
                lineItem.customAttributes.push ({
                    key: '_LPROP',
                    value: 'Final Sale'
                })
            }           

            if(product.extendedReturnIncluded){
                lineItem.customAttributes.push ({
                    key: '_LUGGAGE_RETURN_POLICY',
                    value: 'LUGGAGE_RETURN_100_DAYS'
                })
            }

            if (product.listPrice) {
                lineItem.customAttributes.push({
                    key: '_COMPAREATPRICE',
                    value: JSON.stringify({
                        price: product.listPrice,
                        display: product.listDisplay
                    })
                })
            }

            const lineItemsToAdd = [lineItem]

            let cartProductQuantity

            ShopifyService.fetchCheckoutById(this.checkoutId)
                .then(rsp => {
                    return rsp.data.node
                })
                .then((checkout) => {
                    checkout.lineItems.forEach(item => {
                        if (item.variant.sku === product.sku) {
                            cartProductQuantity = item.quantity
                        }
                    })

                    let allowedQuantity

                    if (cartProductQuantity === undefined) {
                        allowedQuantity = product.availableQuantity
                    } else {
                        allowedQuantity = product.availableQuantity - cartProductQuantity
                    }

                    if (quantity <= allowedQuantity) {
                        HSCO.DataLayer.addingToCart(product)

                        ShopifyService.addLineItems(this.checkoutId, lineItemsToAdd)
                            .then((data) => {
                                return deferred.resolve({
                                    success: true,
                                    data,
                                })
                            })
                    } else {
                        return deferred.reject({
                            success: false,
                            responseText: 'Max quantity',
                        })
                    }
                })

            return deferred.promise();
        },
        /**
         * Add gift card to cart
         *
         * @param {Array.<Object>} values - product custom attributes
         * @param {string} variantId - unique variant identifier
         * @param {string} variantSku - unique variant SKU
         * @returns {Promise<Object>}
         */
        addGiftCardToCart (values, variantId, variantSku) {
            try {
                const lineItemsToAdd = [{
                    variantId,
                    quantity: 1
//                    customAttributes: values.map(({ name, value }) => ({
//                        key: name,
//                        value
//                    }))
                }]

                HSCO.DataLayer.addingToCart(variantSku)

                return ShopifyService.addLineItems(this.checkoutId, lineItemsToAdd)
            } catch (e) {
                throw new Error('No gift card variant')
            }
        },
        getCountry () {
            return options.country;
        },
        getLocale () {
            let index = options.country.lastIndexOf("/");
            return options.country.substring(index + 1);
        },
        getUser () {
            if (!USER) {
                var data = {
                    cartCount: Cookies.get('ep-cartcount') || "0",
                    role: this.getCustomerRole(),
                }

                var noToken = !data.role

                if (noToken){
                    data.role = "PUBLIC";
                }

                USER = {
                    deferred : $.Deferred(),
                    data : data,
                    getCartCount : function() {
                        return data.cartCount;
                    },
                    getRole : function() {
                        return data.role;
                    }
                }

                if (noToken) {
                    USER.deferred = this.login()
                    .then(() => {
                        USER.deferred = $.Deferred()
                        return USER.deferred.resolve(USER)
                    })
                }
                else {
                    return USER.deferred.resolve(USER)
                }
            }

            return USER.deferred;
        },
        /**
         * @param {string} activationUrl
         * @param {string} password
         * @returns {Promise<Object>}
         */
        activateAccount (activationUrl, password) {
            const deferred = $.Deferred()

            ShopifyService.activateAccount(activationUrl, password)
                .then(response => {
                    if (response.errors) {
                        return deferred.reject({
                            success: false
                        })
                    }

                    return deferred.resolve({
                        success: true
                    })
                })
                .catch(() => {
                    return deferred.reject({
                        success: false
                    })
                })

            return deferred.promise()
        },
        getAccount () {
            if (USER.account){
                return USER.account.deferred;
            }

            const deferred = $.Deferred()

            ShopifyService.queryCustomer()
                .then(customerResponse => {
                    const { data: { customer } } = customerResponse
                    const account = {
                        givenName: customer.firstName,
                        familyName: customer.lastName,
                        email: customer.email,
                        lastIncompleteChceckoutId: customer.lastIncompleteCheckout?.id,
                        deferred,
                    }

                    USER.account = account

                    return deferred.resolve(account)
                })

            return deferred.promise()
        },
        updateAccount (account) {
            const deferred = $.Deferred()

            const updateNewsletter = () => {
                const deferredNewsletter = $.Deferred()

                if (!account.changesubscription) {
                    return deferredNewsletter.resolve({ success: true })
                }
                
                var data = account

                if (!account.subscribe) {
                    data.countryCode = hscoData.countryCode

                    $.ajax({
                        type: 'POST',
                        url: '/api/storefront/newsletter/unsubscribe',
                        data: JSON.stringify(data),
                        contentType: 'application/json'
                    })
                    .done(data => {
                        return deferredNewsletter.resolve({
                            success: true,
                            data,
                        })
                    })
                    .fail(() => {
                        return deferredNewsletter.resolve({
                            success: false,
                            responseText: `We're unable to update your subscription status at this time. Please try again later.`
                        })
                    })
                }
                else {
                    data.locale = Commerce.getLocale()
                    data.source = 'website_account'

                    Newsletter.subscribe(data)
                    .done(data => {
                        return deferredNewsletter.resolve({
                            success: true,
                            data,
                        })
                    })
                    .fail(() => {
                        return deferredNewsletter.resolve({
                            success: false,
                            responseText: `We're unable to update your subscription status at this time. Please try again later.`
                        })
                    })
                }

                return deferredNewsletter.promise()
            }

            updateNewsletter()
                .then(({ success: newsletterStatusUpdated, responseText }) => {
                    const newsletterRsp = {
                        newsletterFailed: !newsletterStatusUpdated,
                        newsletterErrorMessage: responseText,
                    }

                    ShopifyService.updateCustomer({
                        email: account.email,
                        firstName: account.givenName,
                        lastName: account.familyName,
                        acceptsMarketing: account.subscribe ? true : false,
                    })
                        .then(customerUpdateResponse => {
                            const { data, errors } = customerUpdateResponse

                            if (data?.customerUpdate?.customerUserErrors?.length || errors?.length) {
                                return deferred.reject({
                                    ...newsletterRsp,
                                    success: false,
                                    responseText: data?.customerUpdate?.customerUserErrors.map(error => error.message).join('; ') || errors.map(error => error.message).join('; '),
                                })
                            }

                            const accessToken = data.customerUpdate.customerAccessToken.accessToken
                            localStorage.setItem('customer-token', accessToken)

                            return deferred.resolve({
                                ...newsletterRsp,
                                success: true,
                            })
                        })
                        .catch(ex => {
                            deferred.reject({
                                ...newsletterRsp,
                                success: false,
                                responseText: ex.message,
                            })
                        })
                })

            return deferred.promise()
        },
        login (username, password) {
            var deferred = $.Deferred()

            if (!(username && password)) {
                return deferred.resolve({
                    success: false,
                })
            }

            if (hscoData.klaviyoEnabled) {
                try {
                    klaviyo.identify({
                        "email" : username
                    });
                } catch(e) {
                    console.info(e);
                }
            }

            ShopifyService.createAccessToken(username, password)
                .then(customerAccessTokenResponse => {
                    const { data, errors } = customerAccessTokenResponse

                    if (data?.customerAccessTokenCreate?.customerUserErrors?.length || errors?.length) {
                        return deferred.reject({
                            success: false,
                            responseText: data?.customerAccessTokenCreate?.customerUserErrors.map(error => error.message).join('; ') || errors.map(error => error.message).join('; '),
                        })
                    }

                    const accessToken = data?.customerAccessTokenCreate?.customerAccessToken?.accessToken

                    if (!accessToken) {
                        return deferred.reject({
                            success: false,
                            msg: '',
                            errorCode: '',
                        })
                    }

                    localStorage.setItem('customer-token', accessToken)
                    setCookie('shop-role', 'REGISTERED')

                    return accessToken
                })
                .then(this.getAccount)
                .then(data => {
                    const { lastIncompleteChceckoutId } = data
                    const accessToken = localStorage.getItem('customer-token')
                    const shouldRestoreCart = !this.hasItemsInCart && !!lastIncompleteChceckoutId

                    if (shouldRestoreCart) {
                        this.setCheckoutId(lastIncompleteChceckoutId)
                    }

                    return ShopifyService.associateCustomerWithCheckout(this.checkoutId, accessToken)
                        .then((associateCustomerWithCheckoutResponse) => {
                            const { data, errors } = associateCustomerWithCheckoutResponse

                            this.checkoutUrl = data?.checkoutCustomerAssociateV2?.checkout?.webUrl

                            if (data?.checkoutCustomerAssociateV2?.checkoutUserErrors?.length || errors?.length) {
                                return deferred.reject({
                                    success: false,
                                    msg: '',
                                    errorCode: '',
                                })
                            }

                            return deferred.resolve({
                                success: true,
                                msg: '',
                                errorCode: '',
                            })
                        })
                })

            return deferred.promise()
        },
        handleCommerceRedirect (target) {
            var deferred = $.Deferred()
            var returnUrl = target.startsWith('http') ? target : `${window.location.origin}${target}`

            fetch('/bin/herschel/form/multipass', {
                method: 'POST',
                body: JSON.stringify({
                    countryPage: window.COMMERCE_OPTIONS.country,
                    accessToken: localStorage.getItem('customer-token'),
                    timestamp: new Date().toISOString(),
                    returnUrl,
                    redirect: false,
                }),
                cache: 'no-store',
            })
            .then(r => r.json())
            .then(result => {
                if (result.success && result.data?.redirectUrl) {
                    return deferred.resolve(result.data.redirectUrl)
                }

                return deferred.reject("Unable to create redirect URL")
            })
            .catch(e => {
                console.error(e)
                return deferred.reject(e.message)
            })

            return deferred.promise()
        },
        logout () {
            var deferred = $.Deferred()

            ShopifyService.deleteAccessToken()
                .then(customerAccessTokenResponse => {
                    const { data, errors } = customerAccessTokenResponse

                    if (errors?.length ||  data?.customerAccessTokenDelete?.userErrors?.length) {
                        return deferred.reject({
                            success: false
                        })
                    }
                })
                .finally(() => {
                    const shopifyLogoutUrl = `https://${COMMERCE_OPTIONS.shopify.domain}/commerce/logout`
                    // Remove no matter what - success or failure
                    localStorage.removeItem('customer-token')
                    localStorage.removeItem('checkout-id')
                    setCookie('shop-role', null, 0)
                    location.href = shopifyLogoutUrl
                })

            return deferred.promise()
        },
        register (account) {
            var deferred = $.Deferred()

            const customerCreateInput = {
                email: account.email,
                password: account.password,
                firstName: account.firstName,
                lastName: account.lastName,
            }

            ShopifyService.createCustomer(customerCreateInput)
                .then(customerCreateResponse => {
                    const { data, errors } = customerCreateResponse

                    if (data?.customerCreate?.customerUserErrors?.length || errors?.length) {
                        return deferred.reject({
                            success: false,
                            responseText: data?.customerCreate?.customerUserErrors?.map(error => error.message).join('; ') || errors?.map(error => error.message).join('; '),
                        })
                    }

                    return deferred.resolve({
                        success: true,
                    })
                })

            return deferred.promise()
        },
        resetPassword (email) {
            var deferred = $.Deferred()

            ShopifyService.requestResetPassword(email)
                .then(customerRecoverResponse => {
                    if (customerRecoverResponse?.errors?.length) {
                        return deferred.reject({
                            success: false,
                            // responseText: customerRecoverResponse.map(errors => errors.message).join('; '),
                        })
                    }

                    return deferred.resolve({
                        success: true,
                    })
                })

            return deferred.promise()
        },
        resetPasswordWithToken (password, token) {
            var deferred = $.Deferred()
            const resetUrl = `https://${window.COMMERCE_OPTIONS.shopify.domain}/account/reset/${token}`

            ShopifyService.resetPasswordWithUrl(password, resetUrl)
                .then(customerResetByUrlResponse => {
                    const { data, error } = customerResetByUrlResponse

                    if (error?.length || data?.customerResetByUrl?.customerUserErrors?.length) {
                        return deferred.reject({
                            success: false,
                            // responseText: customerUserErrors.map(errors => errors.message).join('; '),
                        })
                    }

                    return deferred.resolve({
                        success: true,
                        msg: '',
                        errorCode: '',
                    })
                })

            return deferred.promise()
        },
        updatePassword (currentPassword, newPassword) {
            const deferred = $.Deferred()

            const setNewPassword = () => {
                const deferredPassword = $.Deferred()

                ShopifyService.updateCustomer({ password: newPassword })
                    .then(customerUpdateResponse => {
                        const { data, errors } = customerUpdateResponse

                        if (data?.customerUpdate?.customerUserErrors?.length || errors?.length) {
                            return deferredPassword.reject({
                                success: false,
                                responseText: data?.customerUpdate?.customerUserErrors.map(error => error.message).join('; ') || errors.map(error => error.message).join('; '),
                            })
                        }

                        const accessToken = data.customerUpdate.customerAccessToken.accessToken
                        localStorage.setItem('customer-token', accessToken)

                        return deferredPassword.resolve({
                            success: true,
                        })
                    })

                return deferredPassword.promise()
            }

            this.getAccount()
            .done(({ email }) => {
                return this.login(email, currentPassword)
                .done(() => {
                    return setNewPassword()
                    .done(() => {
                        return deferred.resolve({
                            success: true,
                        })
                    })        
                    .fail(ex => {
                        return deferred.reject({
                            success: false,
                            responseText: ex.message,
                        })
                    })    
                })    
                .fail(ex => {
                    return deferred.reject({
                        success: false,
                        responseText: ex.message,
                    })
                })
            })
            .fail(ex => {
                return deferred.reject({
                    success: false,
                    responseText: ex.message,
                })
            })

            return deferred.promise()
        },
        getAddresses () {
            const deferred = $.Deferred()
            const getAddressData = (cursor) => ShopifyService.getCustomerAddress(cursor)
            const transformAddress = (address, defaultId) => ({
                addressURI: address.id,
                countryName: address.countryCodeV2,
                extendedAddress: address.address2,
                id: address.id,
                isDefault: address.id == defaultId,
                locality: address.city,
                phoneNumber: address.phone,
                postalCode: address.zip,
                region: address.provinceCode,
                shipToFamilyName: address.lastName,
                shipToGivenName: address.firstName,
                streetAddress: address.address1,  
            })

            getAddressData()
                .then(customerResponse => {
                    const { error, data } = customerResponse

                    if (error?.length) {
                        return deferred.resolve({
                            success: false,
                            responseText: customerUserErrors.map(errors => errors.message).join('; '),
                            addresses: [],
                        })
                    }

                    if (!data?.customer?.addresses) {
                        return deferred.resolve({
                            success: false,
                            responseText: 'No customer address data',
                            addresses: [],
                        })
                    }

                    // let pageInfo = customer.addresses.pageInfo
                    const addresses = data.customer.addresses.nodes.map(address => transformAddress(address, data.customer.defaultAddress.id))

                    deferred.resolve({
                        success: true,
                        addresses,
                    })
                })

            return deferred.promise()
        },
        updateAddress (address) {
            const deferred = $.Deferred()

            ShopifyService.updateCustomerAddress(address.addressId, {
                address1: address.streetAddress,
                address2: address.extendedAddress,
                city: address.locality,
                country: address.countryName,
                firstName: address.shipToGivenName,
                lastName: address.shipToFamilyName,
                province: address.region,
                zip: address.postalCode,
                phone: address.phoneNumber,
            })
                .then(customerAddressUpdateResponse => {
                    const { data, errors } = customerAddressUpdateResponse

                    if (data?.customerAddressUpdate?.customerUserErrors?.length || errors?.length) {
                        return deferred.resolve({
                            success: false,
                            responseText: data?.customerAddressUpdate?.customerUserErrors.map(error => error.message).join('; ') || errors.map(error => error.message).join('; '),
                        })
                    }

                    return deferred.resolve({
                        success: true,
                    })
                })
                .catch(ex => {
                    deferred.resolve({
                        success: false,
                        responseText: ex.message,
                    })
                })

            return deferred.promise()
        },
        removeAddress (addressId, addressUrl) {
            const deferred = $.Deferred()

            ShopifyService.deleteCustomerAddress(addressUrl)
                .then(customerAddressDeleteResponse => {
                    const { data, errors } = customerAddressDeleteResponse

                    if (data?.customerAddressDelete?.customerUserErrors?.length || errors?.length) {
                        return deferred.resolve({
                            success: false,
                            responseText: data?.customerAddressDelete?.customerUserErrors.map(error => error.message).join('; ') || errors.map(error => error.message).join('; '),
                        })
                    }

                    return deferred.resolve({
                        success: true,
                    })
                })
                .catch(ex => {
                    deferred.resolve({
                        success: false,
                        responseText: ex.message,
                    })
                })
            
            return deferred.promise()
        },
        setDefaultAddress (addressId) {
            const deferred = $.Deferred()

            ShopifyService.updateDefaultCustomerAddress(addressId)
                .then(customerDefaultAddressUpdateResponse => {
                    const { data, errors } = customerDefaultAddressUpdateResponse

                    if (data?.customerDefaultAddressUpdate?.customerUserErrors?.length || errors?.length) {
                        return deferred.reject({
                            success: false,
                            responseText: data?.customerDefaultAddressUpdate?.customerUserErrors.map(error => error.message).join('; ') || errors.map(error => error.message).join('; '),
                        })
                    }

                    return deferred.resolve({
                        success: true,
                    })
                })
                .catch(ex => {
                    deferred.reject({
                        success: false,
                        responseText: ex.message,
                    })
                })

            return deferred.promise()
        },
        getOrders (filter) {
            const deferred = $.Deferred()
            const getOrderData = (cursor) => ShopifyService.getOrderData(cursor, filter)
            const transformOrder = order => {
                const me = this
                const products = []
                const fulfillments = []
                let totalQty = 0, orderDiscount = 0
                let trackingURL, trackingNumber

                order.lineItems.nodes.forEach(item => {
                    totalQty += item.quantity
                    item.discountAllocations.forEach(item => {
                        orderDiscount += Number(item.allocatedAmount.amount)
                    })
                })

                order.shippingDiscountAllocations.forEach(item => {
                    orderDiscount += Number(item.allocatedAmount.amount)
                })

                order.totalDiscount = {
                    amount: orderDiscount
                }

                order.lineItems.nodes.forEach(item => {
                    products.push(me.transformLineItemOrder(item, order))
                })

                order.successfulFulfillments.forEach(fulfillment => {
                    const urls = [], numbers = []

                    fulfillment.trackingInfo.forEach(tracking => {
                        urls.push(tracking.url)
                        numbers.push(tracking.number)
                    })

                    if (urls.length) {
                        trackingURL = urls.join(',') || trackingURL
                        trackingNumber = numbers.join(',') || trackingNumber
                    }

                    const lineItems = []

                    fulfillment.fulfillmentLineItems.nodes.map(item => { 
                        const fulfilledItem = products.find(product => product.id == item.lineItem?.variant?.id)
                        
                        if (fulfilledItem) {
                            lineItems.push({
                                ...fulfilledItem,
                                quantity: item.quantity,
                            })
                        }
                    })

                    fulfillments.push({
                        trackingURL: urls.join(','),
                        trackingNumber: numbers.join(','),
                        lineItems,
                    })
                })

                return {
                    amount: order.totalPrice.amount,
                    amountDisplay: HSCO.Currency.setCurrency(order.totalPrice.amount),
                    cartQuantity: totalQty,
                    discountTotal: orderDiscount,
                    discountTotalDisplay: HSCO.Currency.setCurrency(orderDiscount),
                    id: order.id,
                    name: order.name,
                    currency: order.currencyCode,
                    orderId: order.id,
                    orderURI: order.id,    
                    purchaseDateDisplayValue: dayjs(order.processedAt).format("dddd, MMMM Do, YYYY, h:mm:ss a, [UTC]Z"),
                    purchaseDateValue: order.processedAt,
                    purchaseNumber: order.name,
                    shipmentTotal: order.totalShippingPrice.amount,
                    status: me.mapOrderStatus(order.fulfillmentStatus, order.financialStatus),
                    subtotal: order.subtotalPrice.amount,
                    subtotalDisplay: HSCO.Currency.setCurrency(order.subtotalPrice.amount),
                    taxAmount: order.totalTax.amount,
                    taxAmountDisplayValue: HSCO.Currency.setCurrency(order.totalTax.amount),
                    trackingURL,
                    fulfillments,
                    products,
                }
            }

            getOrderData()
            .then(customerResponse => {
                const { error, data } = customerResponse

                if (error?.length) {
                    return deferred.resolve({
                        success: false,
                        responseText: customerUserErrors.map(errors => errors.message).join('; '),
                        addresses: [],
                    })
                }

                if (!data?.customer?.orders) {
                    return deferred.resolve({
                        success: false,
                        responseText: 'No customer order data',
                        orders: [],
                    })
                }

                // let pageInfo = customer.orders.pageInfo
                const orders = data.customer.orders.nodes.map(transformOrder)

                deferred.resolve({
                    success: true,
                    orders,
                })
            })

            return deferred.promise()
        },
        getLimitedOrders() {
            const deferred = $.Deferred()
            const getOrderData = (cursor) => ShopifyService.getOrders(cursor)
            const transformOrder = order => ({
                href: order.id,
                id: order.id,
                purchaseDateDisplayValue: dayjs(order.processedAt).format("dddd, MMMM Do, YYYY, h:mm:ss a, [UTC]Z"),
                purchaseDateValue: order.processedAt,
                purchaseNumber: order.name,
                name: order.name
            })

            getOrderData()
                .then(customerResponse => {
                    const { data: { customer }} = customerResponse

                    if (!customer?.orders) {
                        return deferred.resolve({
                            success: false,
                            responseText: 'No customer order data',
                            orders: [],
                        })
                    }

                    // let pageInfo = customer.orders.pageInfo
                    const orders = customer.orders.nodes.map(transformOrder)

                    deferred.resolve({
                        success: true,
                        orders,
                    })
                })

            return deferred.promise() 
        },
        getOrder (data) {
            const deferred = $.Deferred()
            const { id } = data
            const idParts = id.split('/')
            const orderId = idParts[idParts.length - 1]?.split('?')[0]

            this.getOrders(`id:${orderId}`)
            .done(({ success, orders }) => {
                if (success && orders.length) {
                    orders.forEach(order => {
                        if (order.id == id) {
                            const determineReturnEligibility = (lineItem, fulfilled) => {
                                // TODO: define conditions that determine eligibility,
                                // * Product type
                                // * Age of order
                                // * Already returned (or requested)
                                return {
                                    quantity: fulfilled ? lineItem.quantity : 0, 
                                    reason: '' 
                                }
                            }
                            const extractShopifyId = (shopifyId) => {
                                const parts = shopifyId.split('/')

                                return parts[parts.length -1]
                            }
                            // Pass Compare at Price value only if is different than price
                            const getCompareAtPrice = (lineItem) => {
                                const compareAtPrice = lineItem.customAttributes?.find(item => item.key === '_COMPAREATPRICE')

                                if (!compareAtPrice) {
                                    return null
                                }

                                const compareAtPriceValue = JSON.parse(compareAtPrice.value)

                                return compareAtPriceValue.price > lineItem.unitTotals.price ? compareAtPriceValue.price : null
                            }

                            const mapLineItem = (lineItem, fulfilled) => ({
                                variantId: extractShopifyId(lineItem.id),
                                productId: extractShopifyId(lineItem.productId),
                                code: lineItem.sku,
                                display_name: lineItem.name,
                                color_name: lineItem.color_name,
                                size: lineItem.size,
                                oneSize: lineItem.oneSize,            
                                imagePath: lineItem.imagePath,
                                quantity: lineItem.quantity,
                                compareAtPrice: getCompareAtPrice(lineItem),
                                price: {
                                    amount: lineItem.unitTotals.price,
                                    currency: lineItem.currency,
                                    display: lineItem.unitTotals.priceDisplay,
                                },
                                returnEligibility: determineReturnEligibility(lineItem, fulfilled),
                            })

                            const shipments = order.fulfillments.map(fulfillment => {
                                const lineItems = [] 
                                let shipmentTotal = 0,
                                    shipmentSubtotal = 0,
                                    shipmentTax = 0,
                                    shipmentShipping = 0,
                                    shipmentDiscount = 0
                                
                                fulfillment.lineItems.forEach(lineItem => {
                                    lineItems.push(mapLineItem(lineItem, true))
                                    shipmentSubtotal += lineItem.unitTotals.price * lineItem.quantity
                                    shipmentTax += lineItem.unitTotals.tax * lineItem.quantity
                                    shipmentShipping += lineItem.unitTotals.shipping * lineItem.quantity
                                    shipmentDiscount += lineItem.unitTotals.discount * lineItem.quantity

                                    const matchingIndex = order.products.findIndex(product => product.id == lineItem.id)

                                    if (matchingIndex !== -1) {
                                        const matchingLine = order.products[matchingIndex]
                                        matchingLine.quantity -= lineItem.quantity
                                    }
                                })

                                shipmentTotal += shipmentSubtotal + shipmentTax + shipmentShipping - shipmentDiscount

                                const data = {
                                    lineitems: lineItems,
                                    discount: [{
                                        amount: shipmentDiscount,
                                        currency: order.currencyCode,
                                        display: HSCO.Currency.setCurrency(shipmentDiscount),
                                    }],
                                    returnform: {
                                        'return-items': [],
                                    },
                                    returns: null,
                                    shipmentId: fulfillment.trackingNumber,
                                    shippingOption: {
                                        cost: [{
                                            amount: shipmentShipping,
                                            currency: order.currencyCode,
                                            display: HSCO.Currency.setCurrency(shipmentShipping),
                                        }]
                                    },
                                    status: {
                                        code: 'SHIPPED'
                                    },
                                    tax: {
                                        total: {
                                            amount: shipmentTax,
                                            currency: order.currencyCode,
                                            display: HSCO.Currency.setCurrency(shipmentTax),
                                        }
                                    },
                                    total: {
                                        amount: shipmentTotal, 
                                        currency: order.currencyCode, 
                                        display: HSCO.Currency.setCurrency(shipmentTotal)
                                    },
                                    trackingURL: fulfillment.trackingURL,
                                    trackingNumber: fulfillment.trackingNumber,
                                }

                                return data
                            })

                            const unshipped = []
                            let unshippedTotal = 0,
                            unshippedSubtotal = 0,
                            unshippedTax = 0,
                            unshippedShipping = 0,
                            unshippedDiscount = 0

                            order.products.forEach(product => {
                                if (product.quantity) {
                                    unshipped.push(mapLineItem(product, false))
                                    unshippedSubtotal += product.unitTotals.price * product.quantity
                                    unshippedTax += product.unitTotals.tax * product.quantity
                                    unshippedShipping += product.unitTotals.shipping * product.quantity
                                    unshippedDiscount += product.unitTotals.discount * product.quantity
                                }
                            })

                            unshippedTotal += unshippedSubtotal + unshippedTax + unshippedShipping - unshippedDiscount

                            if (unshipped.length) {
                                const data = {
                                    lineitems: unshipped,
                                    discount: [{
                                        amount: unshippedDiscount,
                                        currency: order.currencyCode,
                                        display: HSCO.Currency.setCurrency(unshippedDiscount),
                                    }],
                                    returnform: {
                                        'return-items': [],
                                    },
                                    returns: null,
                                    shipmentId: null,
                                    shippingOption: {
                                        cost: [{
                                            amount: unshippedShipping,
                                            currency: order.currencyCode,
                                            display: HSCO.Currency.setCurrency(unshippedShipping),
                                        }]
                                    },
                                    status: {
                                        code: order.status
                                    },
                                    tax: {
                                        total: {
                                            amount: unshippedTax,
                                            currency: order.currencyCode,
                                            display: HSCO.Currency.setCurrency(unshippedTax),
                                        }
                                    },
                                    total: {
                                        amount: unshippedTotal, 
                                        currency: order.currencyCode, 
                                        display: HSCO.Currency.setCurrency(unshippedTotal)
                                    },
                                    trackingURL: null,
                                }

                                shipments.unshift(data)
                            }

                            const data = {
                                success,
                                'monetary-total': {
                                    amount: order.amount,
                                    currency: order.currency,
                                    display: order.amountDisplay,
                                },
                                subtotal: {
                                    display: order.subtotalDisplay,
                                },
                                'shipping-total': {
                                    display: HSCO.Currency.setCurrency(order.shipmentTotal),
                                },
                                'discount-total': {
                                    display: order.discountTotalDisplay,
                                },
                                'purchase-number': order.purchaseNumber,
                                'purchase-date': {
                                    'display-value': order.purchaseDateDisplayValue,
                                    value: order.purchaseDateValue,
                                },
                                'tax-total': {
                                    amount: order.taxAmount,
                                    currency: order.currency,
                                    display: order.taxAmountDisplayValue,
                                },
                                status: order.status,
                                shipments,
                            }

                            return deferred.resolve(data)
                        }
                    })
                }

                return deferred.reject({
                    success,
                    responseText: 'Unable to get order data'
                })
            })
            .fail(ex => {
                deferred.reject({
                    success: false,
                    responseText: ex.message
                })
            })

            return deferred.promise()
        },
        checkSubscriptionStatus (email) {
            return $.ajax({
                type: 'POST',
                url: '/api/storefront/newsletter/checkstatus',
                data: JSON.stringify({
                    email : email,
                    countryCode : hscoData.countryCode
                }),
                contentType: 'application/json'
            });
        },
        getCustomerRole() {
            return window.getCookie('shop-role');
        },
        isCustomerRegistered() {
            return this.getCustomerRole() === 'REGISTERED'
        },
        isCustomerPublic() {
            return !this.isCustomerRegistered()
        },
        isAuthenticated() {
            return this.isCustomerRegistered();
        },
        getCustomerHash() {
            return localStorage.getItem('customer-token');
        }
    }
})()

window.Commerce.getUser();
