window.ShopifyService = (function() {
    /**
     * Remove edges, node and __typename from GraphQL response
     *
     * @param {Object} graphQLResponse - GraphQL response
     * @returns {Object} Formatted GraphQL response
     */
    const formatGraphQLResponse = function (graphQLResponse) {
        if (!graphQLResponse) {
            return null
        }
    
        if (graphQLResponse !== Object(graphQLResponse)) {
            return graphQLResponse
        }
    
        const formattedgraphQLResponse = {}
        const isObject = (obj) => {
            return obj !== null && typeof obj === 'object' && !Array.isArray(obj)
        }
    
        Object.keys(graphQLResponse).forEach((key) => {
            if (graphQLResponse[key] && graphQLResponse[key].edges) {
                formattedgraphQLResponse[key] = graphQLResponse[key].edges.map((edge) => formatGraphQLResponse(edge.node))
            } else if (isObject(graphQLResponse[key])) {
                formattedgraphQLResponse[key] = formatGraphQLResponse(graphQLResponse[key])
            }  else if (key !== '__typename') {
                formattedgraphQLResponse[key] = graphQLResponse[key]
            }
        })
    
        return formattedgraphQLResponse
    }
    const fetchStorefrontAPI = (data) => fetch(`https://${window.COMMERCE_OPTIONS.shopify.domain}/api/${window.COMMERCE_OPTIONS.shopify.apiVersion}/graphql`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-shopify-storefront-access-token': window.COMMERCE_OPTIONS.shopify.storefrontAccessToken
        },
        body: JSON.stringify(data),
    })
    .then(r => r.json())
    .then(formatGraphQLResponse)

    const getLanguageCode = () => {
        const locale = window.hscoData.locale || 'en-US'
        const localeParts = locale.split('-')
        const [ lang ] = localeParts

        return lang.toUpperCase()
    }
    const languageCode = getLanguageCode()

    return {
        /**
         * Create new Cart for user
         * {@see} {@link https://shopify.dev/docs/api/storefront/2024-04/mutations/checkoutCreate}
         *
         * @returns {Promise<Object>} | fulfilled: created checkout
         */
        createCheckout() {
            return fetchStorefrontAPI({
                query: `mutation createCheckout($input: CheckoutCreateInput!) @inContext(language: ${languageCode}) {
                    checkoutCreate(
                        input: $input
                    ) {
                        checkout {
                            id
                            completedAt
                            webUrl
                            totalPrice {
                                amount
                            }
                            subtotalPrice {
                                amount
                            }
                        }
                    }
                }`,
                variables: {
                    input: {
                        lineItems: []
                    }
                }
            })
        },
        /**
         * Fetch checkout data by id using Node Query
         * @see {@link https://shopify.dev/docs/api/storefront/2024-04/queries/node|Node}
         *
         * @returns Promise<Object>} | fulfilled: checkout data
         */
        fetchCheckoutById(id) {
            return fetchStorefrontAPI({
                query: `query checkoutQuery($id: ID!) {
                    node(id: $id) {
                        ...on Checkout {
                            id
                            webUrl
                            subtotalPrice {
                                amount
                            }
                            totalPrice {
                                amount
                            }
                            completedAt
                            customAttributes {
                                key
                                value
                            }
                            lineItems (first: 250) {
                                pageInfo {
                                    hasNextPage
                                    hasPreviousPage
                                }
                                edges {
                                    cursor
                                    node {
                                        id
                                        title
                                        variant {
                                            sku
                                            id
                                            product {
                                                id
                                            }
                                            compareAtPrice {
                                                amount
                                            }
                                            price {
                                                amount
                                            }
                                            quantityAvailable
                                            selectedOptions {
                                                name
                                                value
                                            }
                                        }
                                        quantity
                                        customAttributes {
                                            key
                                            value
                                        }
                                        discountAllocations {
                                            allocatedAmount {
                                                amount
                                                currencyCode
                                            }
                                            discountApplication {
                                                ...on AutomaticDiscountApplication {
                                                    title
                                                    value {
                                                        ...on MoneyV2 {
                                                            amount
                                                            currencyCode
                                                        }
                                                        ...on PricingPercentageValue {
                                                            percentage
                                                        }
                                                    }
                                                }
                                                ...on DiscountCodeApplication {
                                                    title: code
                                                    value {
                                                        ...on MoneyV2 {
                                                            amount
                                                            currencyCode
                                                        }
                                                        ...on PricingPercentageValue {
                                                            percentage
                                                        }
                                                    }
                                                }
                                                ...on ManualDiscountApplication {
                                                    title
                                                    value {
                                                        ...on MoneyV2 {
                                                            amount
                                                            currencyCode
                                                        }
                                                        ...on PricingPercentageValue {
                                                            percentage
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }`,
                variables: {
                    id
                }
            })
        },
        /**
         * @see {@link https://shopify.dev/docs/api/storefront/2024-04/mutations/checkoutLineItemsAdd}
         *
         * @param {string} checkoutId - The checkout on which to remove line items.
         * @param {Array<Object>} lineItems - variants to add
         *
         * @returns Promise<Object>} | fulfilled: checkout data
         */
        addLineItems(checkoutId, lineItems) {
            return fetchStorefrontAPI({
                query: `mutation checkoutLineItemsAdd($checkoutId: ID!, $lineItems: [CheckoutLineItemInput!]!) {
                    checkoutLineItemsAdd(checkoutId: $checkoutId, lineItems: $lineItems) {
                        checkout {
                            webUrl
                        }
                    }
                }`,
                variables: {
                    checkoutId,
                    lineItems
                }
            })
        },
        /**
         * @see {@link https://shopify.dev/docs/api/storefront/2024-04/mutations/checkoutLineItemsRemove}
         *
         * @param {string} checkoutId - The checkout on which to remove line items.
         * @param {string} lineItemId - Line item id to remove.
         *
         * @returns Promise<Object>} | fulfilled: checkout data
         */
        removeLineItem(checkoutId, lineItemId) {
            return fetchStorefrontAPI({
                query: `mutation checkoutLineItemsRemove($checkoutId: ID!, $lineItemIds: [ID!]!) {
                    checkoutLineItemsRemove(checkoutId: $checkoutId, lineItemIds: $lineItemIds) {
                        checkout {
                            webUrl
                        }
                    }
                }`,
                variables: {
                    checkoutId,
                    lineItemIds: [lineItemId]
                }
            })
        },
        /**
         * @see {@link https://shopify.dev/docs/api/storefront/2024-04/mutations/checkoutLineItemsUpdate}
         *
         * @param {string} checkoutId - The checkout on which to update line items.
         * @param {string} lineItems - Line items to update.
         *
         * @returns Promise<Object>} | fulfilled: checkout data
         */
        updateLineItems(checkoutId, lineItems) {
            return fetchStorefrontAPI({
                query: `mutation checkoutLineItemsUpdate($checkoutId: ID!, $lineItems: [CheckoutLineItemUpdateInput!]!) {
                    checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
                      checkout {
                        webUrl
                      }
                      checkoutUserErrors {
                        message
                      }
                    }
                  }
                  `,
                variables: {
                    checkoutId,
                    lineItems
                }
            })
        },
        /**
         * @see {@link https://shopify.dev/docs/api/storefront/2024-04/queries/products}
         *
         * @param {Array<String>} ids 
         * @returns Promise<Object>}
         */
        getProductsById (ids) {
            const query = ids?.length > 1 ? ids.map(id => `id:${id}`).join(' OR ') : `id:${ids[0]}`

            return fetchStorefrontAPI({
                query: `query productsQuery($query: String) {
                    products(first: 50, query: $query) {
                        edges {
                            node {
                                id
                                title
                                onlineStoreUrl
                                variants(first: 250) {
                                    edges {
                                        node {
                                            id
                                            availableForSale
                                            sku
                                            product {
                                                id
                                            }
                                            quantityAvailable
                                            price {
                                                amount
                                                currencyCode
                                            }
                                            compareAtPrice {
                                                amount
                                                currencyCode
                                            }
                                            selectedOptions {
                                                name
                                                value
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }`,
                variables: {
                    query
                }
            })
        },
        /**
         * @see {@link https://shopify.dev/custom-storefronts/customer-accounts#step-2-activate-a-customer|Account Activation}
         *
         * @param {string} activationUrl
         * @param {string} password
         *
         * @returns {Promise<Object>}
         */
        activateAccount (activationUrl, password) {
            return fetchStorefrontAPI({
                query: `mutation($activationUrl: URL!, $password: String!) {
                    customerActivateByUrl(
                        activationUrl: $activationUrl,
                        password: $password
                    ) {
                        customer {
                            id
                        }
                        customerUserErrors {
                            code
                            field
                            message
                        }
                    }
                }`,
                variables: {
                    activationUrl,
                    password
                }
            })
        },
        /**
         * @see {@link https://shopify.dev/docs/api/storefront/2024-04/queries/customer|Customer Query}
         *
         * @returns {Promise<Object>}
         */
        queryCustomer () {
            return fetchStorefrontAPI({
                query: `query customerQuery($customerAccessToken: String!) {
                    customer(customerAccessToken: $customerAccessToken) {
                        id
                        firstName
                        lastName
                        email
                        lastIncompleteCheckout {
                            id
                        }
                    }
                }`,
                variables: {
                    customerAccessToken: localStorage.getItem('customer-token')
                }
            })
        },
        /**
         * @see {@link https://shopify.dev/docs/api/storefront/2024-04/mutations/customerAccessTokenCreate|Create Customer Access Token}
         *
         * @param {string} username
         * @param {string} password
         *
         * @returns {Promise<Object>}
         */
        createAccessToken (username, password) {
            const customerAccessTokenCreateInput = {
                email: username,
                password: password,
            }

            return fetchStorefrontAPI({
                query: `mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
                    customerAccessTokenCreate(input: $input) {
                        customerUserErrors {
                            code
                            field
                            message
                        }
                        customerAccessToken {
                            accessToken
                            expiresAt
                        }
                    }
                }`,
                variables: {
                    input: customerAccessTokenCreateInput
                }
            })
        },
        /**
         * @see {@link https://shopify.dev/docs/api/storefront/2024-04/mutations/checkoutCustomerAssociateV2}
         *
         * @param {string} checkoutId
         * @param {string} customerAccessToken
         *
         * @returns {Promise<Object>}
         */
        associateCustomerWithCheckout (checkoutId, customerAccessToken) {
            return fetchStorefrontAPI({
                query: `mutation associateCustomerWithCheckout($checkoutId: ID!, $customerAccessToken: String!) {
                    checkoutCustomerAssociateV2(checkoutId: $checkoutId, customerAccessToken: $customerAccessToken) {
                        checkout {
                            webUrl
                        }
                        checkoutUserErrors {
                            code
                            field
                            message
                        }
                    }
                }`,
                variables: {
                    checkoutId,
                    customerAccessToken,
                }
            })
        },
        /**
         * @see {@link https://shopify.dev/docs/api/storefront/2024-04/mutations/customerAccessTokenDelete}
         *
         * @returns {Promise<Object>}
         */
        deleteAccessToken () {
            return fetchStorefrontAPI({
                query: `mutation customerAccessTokenDelete($customerAccessToken: String!) {
                    customerAccessTokenDelete(customerAccessToken: $customerAccessToken) {
                        deletedAccessToken
                        deletedCustomerAccessTokenId
                        userErrors {
                            field
                            message
                        }
                    }
                }`,
                variables: {
                    customerAccessToken: localStorage.getItem('customer-token')
                }
            })
        },
        /**
         * @see {@link https://shopify.dev/docs/api/storefront/2024-04/mutations/customerCreate}
         *
         * @param {Object} customerCreateInput
         *
         * @returns {Promise<Object>}
         */
        createCustomer (customerCreateInput) {
            return fetchStorefrontAPI({
                query: `mutation customerCreate($input: CustomerCreateInput!) {
                    customerCreate(input: $input) {
                        customerUserErrors {
                            code
                            field
                            message
                        }
                        customer {
                            id
                            email
                            firstName
                            lastName
                        }
                    }
                }`,
                variables: {
                    input: customerCreateInput
                }
            })
        },
        /**
         * To limit brute force and discovery attacks, do not return an error message that indicates whether the email is valid or not
         * @see {@link https://shopify.dev/docs/api/storefront/2024-04/mutations/customerRecover}
         *
         * @param {string} email
         *
         * @returns {Promise<Object>}
         */
        requestResetPassword (email) {
            return fetchStorefrontAPI({
                query: `mutation customerRecover($email: String!) {
                    customerRecover(email: $email) {
                        __typename
                    }
                }`,
                variables: {
                    email,
                }
            })
        },
        /**
         * @see {@link https://shopify.dev/docs/api/storefront/2024-04/mutations/customerResetByUrl}
         * @param {string} password - new password
         * @param {string} resetUrl
         *
         * @returns {Promise<Object>}
         */
        resetPasswordWithUrl (password, resetUrl) {
            return fetchStorefrontAPI({
                query: `mutation customerResetByUrl($password: String!, $resetUrl: URL!) {
                    customerResetByUrl(password: $password, resetUrl: $resetUrl) {
                        customer {
                            id
                            email
                        }
                        customerUserErrors {
                            code
                            field
                            message
                        }
                    }
                }`,
                variables: {
                    password,
                    resetUrl,
                }
            })
        },
        /**
         * @see {@link https://shopify.dev/docs/api/storefront/2024-04/mutations/customerUpdate}
         *
         * @param {Object} customer
         *
         * @returns {Promise<Object>}
         */
        updateCustomer (customer) {
            return fetchStorefrontAPI({
                query: `mutation customerUpdate($customer: CustomerUpdateInput!, $customerAccessToken: String!) {
                    customerUpdate(customer: $customer, customerAccessToken: $customerAccessToken) {
                        customerUserErrors {
                            code
                            field
                            message
                        }
                        customerAccessToken {
                            accessToken
                            expiresAt
                        }
                        customer {
                            id
                            firstName
                            lastName
                            email
                        }
                    }
                }`,
                variables: {
                    customerAccessToken: localStorage.getItem('customer-token'),
                    customer
                }
            })
        },
        /**
         * @see {@link https://shopify.dev/docs/api/storefront/2024-04/queries/customer}
         *
         * @param {*} cursor
         *
         * @returns {Promise<Object>}
         */
        getCustomerAddress (cursor) {
            return fetchStorefrontAPI({
                query: `query customerQuery($customerAccessToken: String!, $cursor: String) {
                    customer(customerAccessToken: $customerAccessToken) {
                        id
                        email
                        defaultAddress {
                            id
                        }
                        addresses(first: 20, after: $cursor) {
                            pageInfo {
                                hasNextPage
                                endCursor
                            }
                            nodes {
                                id
                                address1
                                address2
                                city
                                country
                                countryCodeV2
                                firstName
                                lastName
                                province
                                provinceCode
                                zip
                                phone
                            }
                        }
                    }
                }`,
                variables: {
                    cursor,
                    customerAccessToken: localStorage.getItem('customer-token'),
                }
            })
        },
        /**
         * @see {@link https://shopify.dev/docs/api/storefront/2024-04/mutations/customerAddressUpdate}
         *
         * @param {string} addressId
         * @param {Object} address
         *
         * @returns {Promise<Object>}
         */
        updateCustomerAddress (addressId, address) {
            return fetchStorefrontAPI({
                query: `mutation customerAddressUpdate($addressId: ID!, $address: MailingAddressInput!, $customerAccessToken: String!) {
                    customerAddressUpdate(id: $addressId, address: $address, customerAccessToken: $customerAccessToken) {
                        customerUserErrors {
                            code
                            field
                            message
                        }
                    }
                }`,
                variables: {
                    customerAccessToken: localStorage.getItem('customer-token'),
                    addressId,
                    address
                }
            })
        },
        /**
         * @see {@link https://shopify.dev/docs/api/storefront/2024-04/mutations/customerAddressDelete}
         *
         * @param {string} addressId
         *
         * @returns {Promise<Object>}
         */
        deleteCustomerAddress (addressId) {
            return fetchStorefrontAPI({
                query: `mutation customerAddressDelete($addressId: ID!, $customerAccessToken: String!) {
                    customerAddressDelete(id: $addressId, customerAccessToken: $customerAccessToken) {
                        customerUserErrors {
                            code
                            field
                            message
                        }
                    }
                }`,
                variables: {
                    customerAccessToken: localStorage.getItem('customer-token'),
                    addressId,
                }
            })
        },
        /**
         * @see {@link https://shopify.dev/docs/api/storefront/2024-04/mutations/customerDefaultAddressUpdate}
         *
         * @param {string} addressId
         *
         * @returns {Promise<Object>}
         */
        updateDefaultCustomerAddress (addressId) {
            return fetchStorefrontAPI({
                query: `mutation customerDefaultAddressUpdate($addressId: ID!, $customerAccessToken: String!) {
                    customerDefaultAddressUpdate(addressId: $addressId, customerAccessToken: $customerAccessToken) {
                        customerUserErrors {
                            code
                            field
                            message
                        }
                    }
                }`,
                variables: {
                    customerAccessToken: localStorage.getItem('customer-token'),
                    addressId,
                }
            })
        },
        /**
         * @see {@link https://shopify.dev/docs/api/storefront/2024-04/queries/customer}
         *
         * @param {*} cursor
         *
         * @returns {Promise<Object>}
         */
        getOrders (cursor) {
            return fetchStorefrontAPI({
                query: `query customerQuery($customerAccessToken: String!, $cursor: String) {
                    customer(customerAccessToken: $customerAccessToken) {
                        id
                        email
                        orders(first: 25, after: $cursor, reverse: true) {
                            pageInfo {
                                hasNextPage
                                endCursor
                            }
                            nodes {
                                id
                                name
                                processedAt
                                fulfillmentStatus
                                financialStatus
                                statusUrl
                            }
                        }
                    }
                }`,
                variables: {
                    cursor,
                    customerAccessToken: localStorage.getItem('customer-token'),
                }
            })
        },
        /**
         * @see {@link https://shopify.dev/docs/api/storefront/2024-04/queries/customer}
         *
         * @param {*} cursor
         * @param {string} filter
         *
         * @returns {Promise<Object>}
         */
        getOrderData (cursor, filter) {
            return fetchStorefrontAPI({
                query: `query customerQuery($customerAccessToken: String!, $cursor: String, $filter: String) {
                    customer(customerAccessToken: $customerAccessToken) {
                        id
                        email
                        orders(first: 25, after: $cursor, reverse: true, query: $filter) {
                            pageInfo {
                                hasNextPage
                                endCursor
                            }
                            nodes {
                                id
                                name
                                currencyCode
                                processedAt
                                fulfillmentStatus
                                financialStatus
                                statusUrl
                                subtotalPrice {
                                    amount
                                }
                                totalTax {
                                    amount
                                }
                                totalShippingPrice {
                                    amount
                                }
                                totalPrice {
                                    amount
                                }
                                lineItems(first: 250) {
                                    nodes {
                                        quantity
                                        title
                                        originalTotalPrice {
                                            amount
                                        }
                                        discountedTotalPrice {
                                            amount
                                        }
                                        discountAllocations {
                                            allocatedAmount {
                                                amount
                                            }
                                        }
                                        variant {
                                            id
                                            product {
                                                id
                                            }
                                            title
                                            sku
                                            selectedOptions {
                                                name
                                                value
                                            }
                                            image {
                                                url
                                            }
                                        }
                                        customAttributes {
                                            key
                                            value
                                        }
                                    }
                                }
                                shippingDiscountAllocations {
                                    allocatedAmount {
                                        amount
                                    }
                                }
                                successfulFulfillments(first: 50) {
                                    trackingCompany
                                    trackingInfo {
                                        number
                                        url
                                    }
                                    fulfillmentLineItems(first: 250) {
                                        nodes {
                                            quantity
                                            lineItem {
                                                variant {
                                                    id
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }`,
                variables: {
                    filter,
                    cursor,
                    customerAccessToken: localStorage.getItem('customer-token'),
                }
            })
        }
    }
})()
